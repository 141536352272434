<template>
  <div class="page">
    <base-left-nav-bar></base-left-nav-bar>
    <div class="main">
      <base-top-nav-bar></base-top-nav-bar>
      <div class="mian-wrapper">
        <base-page-header>
          <span slot="r"
            ><slot><a href="/userList">用户管理 — </a></slot></span
          >
          <span slot="m"
            ><slot><a href="/userList">用户列表 </a></slot></span
          >
          <span slot="l"><slot>> 设置身份权限 </slot></span>
        </base-page-header>
        <div class="main-manager">
          <el-form
            :model="userForm"
            :rules="rules"
            ref="userForm"
            label-width="100px"
            class="userForm"
          >
            <el-form-item label="序号：">
              <p>{{ userForm.userid }}</p>
            </el-form-item>
            <el-form-item label="姓名：">
              <p>{{ userForm.name }}</p>
            </el-form-item>
            <el-form-item label="手机号：">
              <p>{{ userForm.phone }}</p>
            </el-form-item>
            <el-form-item label="目前身份：">
              <p>{{ userForm.gtype_name }}</p>
            </el-form-item>
            <el-form-item label="公司：" v-show="flag.company">
              <el-select
                v-model="userForm.companyid"
                placeholder="请分配"
                @change="companyChange"
              >
                <el-option
                  v-for="item in companyOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="公司身份：" v-show="flag.company">
              <el-select
                v-model="type.company"
                placeholder="请选择"
                @change="companyGtypeChange"
              >
                <el-option
                  label="油田员工"
                  :value="-1"
                  v-show="authFlag.OILFIELD_RELATED_USER"
                ></el-option>
                <el-option
                  label="公司负责人"
                  :value="2"
                  v-show="authFlag.COMPANY_SET_HEAD"
                ></el-option>
                <el-option
                  label="公司管理员"
                  :value="3"
                  v-show="authFlag.COMPANY_SET_ADMIN"
                ></el-option>
                <el-option
                  label="普通员工"
                  :value="11"
                  v-show="authFlag.OILFIELD_RELATED_USER"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="油田：" v-show="flag.oilfield">
              <el-select
                v-model="userForm.oilfieldid"
                placeholder="请分配"
                @change="oilfieldChange"
              >
                <el-option
                  v-for="item in oilfieldOpitons"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="油田身份：" v-show="flag.oilfieldRole">
              <el-select
                v-model="type.oilfield"
                placeholder="请选择"
                @change="oilfieldGtypeChange"
              >
                <el-option
                  label="设备员工"
                  :value="-2"
                  v-show="authFlag.DEVICE_RELATED_USER"
                ></el-option>
                <el-option
                  label="油田负责人"
                  :value="4"
                  v-show="authFlag.OILFIELD_SET_HEAD"
                ></el-option>
                <el-option
                  label="油田管理员"
                  :value="5"
                  v-show="authFlag.OILFIELD_SET_ADMIN"
                ></el-option>
                <el-option
                  label="油田操作员"
                  :value="6"
                  v-show="authFlag.OILFIELD_SET_OPERATOR"
                ></el-option>
                <el-option
                  label="油田观察员"
                  :value="7"
                  v-show="authFlag.OILFIELD_SET_OBSERVER"
                ></el-option>
                <el-option
                  label="普通员工"
                  :value="11"
                  v-show="authFlag.OILFIELD_ASSIGN_DEVICE"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备：" v-show="flag.device">
              <el-select
                v-model="userForm.device_name"
                placeholder="请选择"
                @change="deviceChange"
              >
                <el-option
                  v-for="item in deviceOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  v-show="authFlag.DEVICE_RELATED_USER"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备身份：" v-show="flag.deviceRole">
              <el-select
                v-model="type.device"
                placeholder="请选择"
                @change="deviceGtypeChange"
              >
                <el-option
                  label="设备负责人"
                  :value="8"
                  v-show="authFlag.DEVICE_SET_HEAD"
                ></el-option>
                <el-option
                  label="设备操作员"
                  :value="9"
                  v-show="authFlag.DEVICE_SET_OPERATOR"
                ></el-option>
                <el-option
                  label="设备观察员"
                  :value="10"
                  v-show="authFlag.DEVICE_SET_OBSERVER"
                ></el-option>
                <el-option
                  label="普通员工"
                  :value="11"
                  v-show="authFlag.DEVICE_RELATED_USER"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseLeftNavBar from 'components/base-nav-bar/base-left-nav-bar.vue'
import BaseTopNavBar from 'components/base-nav-bar/base-top-nav-bar.vue'
import BasePageHeader from 'components/base-page-header/base-page-header.vue'

import UserJS from 'network/user/user.js'
import CommonJS from 'assets/js/common.js'

export default {
  inject: ['reload'],
  components: {
    BaseLeftNavBar,
    BaseTopNavBar,
    BasePageHeader
  },
  data() {
    return {
      authFlag: {},
      userForm: {
        userid: '',
        name: '',
        phone: '',
        companyid: '',
        oilfieldid: '',
        device_name: '',
        device_nickname: '',
        gtype_name: '',
        gtype: '' // 身份类型 0:原始管理员 1:系统管理员 2:公司负责人 3:公司管理员 4:油田负责人 5:油田管理员 6:油田操作员 7:油田观察员 8:设备负责人 9:设备操作员 10:设备观察员 11:普通员工
      },
      selectForm: {
        companyid: '',
        company: '',
        oilfieldid: '',
        oilfield: ''
      },
      flag: {
        company: false,
        companyRole: false,
        oilfield: false,
        oilfieldRole: false,
        device: false,
        deviceRole: false
      },
      type: {
        company: '',
        oilfield: '',
        device: ''
      }, // 设置gype
      companyOptions: [],
      oilfieldOpitons: [],
      deviceOptions: [],
      rules: {
        name: [
          { required: true, message: '请输入姓名名称', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
        ],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
          // {
          //   pattern: /^1[34578]\d{9}$/,
          //   message: '目前只支持中国大陆的手机号码'
          // }
        ],
        workid: [{ required: true, message: '请输入工号', trigger: 'blur' }]
      }
    }
  },
  watch: {},
  methods: {
    /**
     * 事件监听
     */
    // 绑定公司
    companyChange(v) {
      this.$confirm('确认绑定？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.selectForm.companyid = this.userForm.companyid
          this.cpmpanyAddUser()
        })
        .catch(() => {
          // 复原
          this.userForm.companyid = this.selectForm.companyid
        })
    },
    // 绑定公司身份
    companyGtypeChange(v) {
      // 重置后面的选项数据
      if (v == -1) {
        // 下一步骤，加载油田
        this.flag.oilfield = true
        this.getOilfieldList()
      } else {
        this.flag.oilfield = false
        this.$confirm('确认绑定？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.selectForm.company = this.type.company
            if (v !== -1) {
              this.userForm.oilfieldid = ''
              this.userForm.device_name = ''
              this.userForm.device_nickname = ''
              this.flag.oilfield = false
              this.flag.oilfieldRole = false
              this.flag.device = false
              this.flag.deviceRole = false
              this.type.oilfield = ''
              this.type.device = ''
            }
            if (v == 2) {
              this.companySetMaster()
            } else if (v == 3) {
              this.cpmpanyAddAdmin()
            } else if (v == 11) {
              this.cpmpanyUserFree()
            }
          })
          .catch(() => {
            this.type.company = this.selectForm.company
            // 复原
            if (this.type.company == -1) {
              this.flag.oilfield = true
              this.userForm.oilfieldid = this.selectForm.oilfieldid
              // this.flag.oilfieldRole = true
            }
          })
      }
    },
    // 绑定油田
    oilfieldChange() {
      this.$confirm('确认绑定？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.oilfieldAddUser()
        })
        .catch(() => {})
    },
    // 绑定油田身份
    oilfieldGtypeChange(v) {
      // 重置后面的选项数据
      this.userForm.device_name = ''
      this.userForm.device_nickname = ''
      this.flag.device = false
      this.flag.deviceRole = false
      this.type.device = ''
      if (v == -2) {
        this.flag.device = true
      } else {
        this.flag.device = false
        this.$confirm('确认绑定？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.selectForm.oilfield = this.type.oilfield
            if (v == 4) {
              this.oilfieldSetMaster()
            } else if (v == 5) {
              this.oilfieldAddAdmin()
            } else if (v == 6) {
              this.oilfieldAddOperator()
            } else if (v == 7) {
              this.oilfieldAddObserver()
            } else if (v == 11) {
              this.oilfieldAddUser()
            }
          })
          .catch(() => {
            this.type.oilfield = this.selectForm.oilfield
          })
      }
    },
    // 绑定油田
    deviceChange() {
      this.deviceGet()
    },
    // 绑定设备身份
    deviceGtypeChange(v) {
      if (v == 8) {
        this.deviceSetMaster()
      } else if (v == 9) {
        this.deviceAddOperator()
      } else if (v == 10) {
        this.deviceAddObserver()
      } else {
        this.deviceUserFree()
      }
    },
    /**
     * 网络请求
     */
    cpmpanyAddUser() {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        userid: this.$route.query.userid,
        companyid: this.userForm.companyid
      }
      UserJS.cpmpanyAddUser(params).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg)
          setTimeout(() => {
            this.reload()
          }, 500)
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    cpmpanyAddAdmin() {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        userid: this.$route.query.userid,
        companyid: this.userForm.companyid
      }
      UserJS.cpmpanyAddAdmin(params).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg)
          this.userForm.gtype_name = '公司管理员'
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    companySetMaster() {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        userid: this.$route.query.userid,
        companyid: this.userForm.companyid
      }
      UserJS.companySetMaster(params).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg)
          this.userForm.gtype_name = '公司负责人'
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    cpmpanyUserFree() {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        userid: this.$route.query.userid,
        companyid: this.userForm.companyid
      }
      UserJS.cpmpanyUserFree(params).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg)
          this.userForm.gtype_name = '普通员工'
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    oilfieldAddAdmin() {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        userid: this.$route.query.userid,
        oilfieldid: this.userForm.oilfieldid
      }
      UserJS.oilfieldAddAdmin(params).then((res) => {
        if (res.code == 0) {
          this.userForm.gtype_name = '油田管理员'
          this.flag.oilfieldRole = true
          this.$message.success(res.msg)
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    oilfieldSetMaster() {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        userid: this.$route.query.userid,
        oilfieldid: this.userForm.oilfieldid
      }
      UserJS.oilfieldSetMaster(params).then((res) => {
        if (res.code == 0) {
          this.userForm.gtype_name = '油田负责人'
          this.flag.oilfieldRole = true
          this.$message.success(res.msg)
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    oilfieldAddOperator() {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        userid: this.$route.query.userid,
        oilfieldid: this.userForm.oilfieldid
      }
      UserJS.oilfieldAddOperator(params).then((res) => {
        if (res.code == 0) {
          this.userForm.gtype_name = '油田操作员'
          this.flag.oilfieldRole = true
          this.$message.success(res.msg)
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    oilfieldAddObserver() {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        userid: this.$route.query.userid,
        oilfieldid: this.userForm.oilfieldid
      }
      UserJS.oilfieldAddObserver(params).then((res) => {
        if (res.code == 0) {
          this.userForm.gtype_name = '油田观察员'
          this.flag.oilfieldRole = true
          this.$message.success(res.msg)
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    oilfieldAddUser() {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        userid: this.$route.query.userid,
        oilfieldid: this.userForm.oilfieldid
      }
      UserJS.oilfieldAddUser(params).then((res) => {
        if (res.code == 0) {
          this.userForm.gtype_name = '普通员工'
          this.flag.oilfieldRole = true
          this.$message.success(res.msg)
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    oilfieldUserFree() {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        userid: this.$route.query.userid,
        oilfieldid: this.userForm.oilfieldid
      }
      UserJS.oilfieldUserFree(params).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg)
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    deviceAddUser() {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        userid: this.$route.query.userid,
        device_name: this.userForm.device_name
      }
      UserJS.deviceAddUser(params).then((res) => {
        if (res.code == 0) {
          this.flag.deviceRole = true
          this.$message.success(res.msg)
        } else {
          this.userForm.device_name = ''
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    deviceAddOperator() {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        userid: this.$route.query.userid,
        device_name: this.userForm.device_name
      }
      UserJS.deviceAddOperator(params).then((res) => {
        if (res.code == 0) {
          this.userForm.gtype_name = '设备操作员'
          this.$message.success(res.msg)
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    deviceSetMaster() {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        userid: this.$route.query.userid,
        device_name: this.userForm.device_name
      }
      UserJS.deviceSetMaster(params).then((res) => {
        if (res.code == 0) {
          this.userForm.gtype_name = '设备负责人'
          this.$message.success(res.msg)
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    deviceAddObserver() {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        userid: this.$route.query.userid,
        device_name: this.userForm.device_name
      }
      UserJS.deviceAddObserver(params).then((res) => {
        if (res.code == 0) {
          this.userForm.gtype_name = '设备观察员'
          this.$message.success(res.msg)
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    deviceUserFree() {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        userid: this.$route.query.userid,
        device_name: this.userForm.device_name
      }
      UserJS.deviceUserFree(params).then((res) => {
        if (res.code == 0) {
          this.flag.deviceRole = true
          this.userForm.gtype_name = '普通员工'
          this.$message.success(res.msg)
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取当前用户
    userGet() {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        userid: this.$route.query.userid
      }
      UserJS.userGet(params).then((res) => {
        if (res.code == 0) {
          const data = res.user_data
          this.userForm = data
          this.selectForm.companyid = data.companyid
          this.selectForm.oilfieldid = data.oilfieldid
          if (data.companyid == 0) {
            this.userForm.companyid = ''
            this.selectForm.companyid = ''
          }
          if (data.oilfieldid == 0) {
            this.userForm.oilfieldid = ''
            this.selectForm.oilfieldid = ''
          } else if (data.deviceid == 0) {
            this.userForm.deviceid = ''
            this.selectForm.deviceid = ''
          }
          switch (data.gtype) {
            case 2:
            case 3:
              this.type.company = data.gtype
              this.flag.company = true
              break
            case 4:
            case 5:
            case 6:
            case 7: // 油田员工
              this.type.company = -1
              this.type.oilfield = data.gtype
              this.flag.company = true
              this.flag.oilfield = true
              this.flag.oilfieldRole = true
              this.getOilfieldList()
              break
            case 8:
            case 9:
            case 10: // 设备员工
              this.type.company = -1
              this.type.oilfield = -2
              this.type.device = data.gtype
              this.flag.company = true
              this.flag.oilfield = true
              this.flag.oilfieldRole = true
              this.flag.device = true
              this.flag.deviceRole = true
              this.getOilfieldList()
              break
            case 11:
              // 公司普通员工
              if (!data.oilfieldid && !data.deviceid) {
                this.type.company = 11
                this.flag.company = true
              }
              // 设备普通员工
              if (data.oilfieldid && data.deviceid) {
                this.type.company = -1
                this.type.oilfield = -2
                this.type.device = 11
                this.flag.company = true
                this.flag.oilfield = true
                this.flag.oilfieldRole = true
                this.flag.device = true
                this.flag.deviceRole = true
                this.getOilfieldList()
                break
              }
              // 油田普通员工
              if (data.oilfieldid) {
                this.type.company = -1
                this.type.oilfield = 11
                this.flag.company = true
                this.flag.oilfield = true
                this.flag.oilfieldRole = true
                this.getOilfieldList()
                break
              }
              break
            default:
              break
          }
          this.selectForm.company = this.type.company
          this.selectForm.oilfield = this.type.oilfield
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取当前设备
    deviceGet() {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        device_name: this.userForm.device_name
      }
      UserJS.deviceGet(params).then((res) => {
        if (res.code == 0) {
          this.deviceAddUser()
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取公司列表
    getCompanyList() {
      const param = {
        session_id: this.$store.state.loginInfo.session_id,
        count: 99,
        page: 0
      }
      UserJS.companyList(param).then((res) => {
        if (res.code == 0) {
          const arr = []
          for (const i of res.company_list) {
            const obj = { value: '', label: '' }
            obj.value = i.companyid
            obj.label = i.name
            arr.push(obj)
          }
          this.companyOptions = arr
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取油田列表
    getOilfieldList() {
      const param = {
        session_id: this.$store.state.loginInfo.session_id,
        companyid: this.userForm.companyid,
        count: 99,
        page: 0
      }
      UserJS.oilfieldList(param).then((res) => {
        if (res.code == 0) {
          this.oilfieldOpitons = []
          const arr = []
          for (const i of res.oilfield_list) {
            const obj = { value: '', label: '' }
            obj.value = i.oilfieldid
            obj.label = i.name
            arr.push(obj)
          }
          this.oilfieldOpitons = arr
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取设备列表
    getDeviceList() {
      const params = {
        session_id: this.$store.state.loginInfo.session_id,
        page: 0,
        count: 100
      }
      UserJS.deviceList(params).then((res) => {
        if (res.code == 0) {
          const arr = []
          for (const i of res.device_list) {
            const obj = { value: '', label: '' }
            obj.value = i.device_name
            obj.label = i.device_name
            arr.push(obj)
          }
          this.deviceOptions = arr
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 查看权限
    authCheck() {
      this.authFlag = CommonJS.authCheck(this.$store.state.loginInfo.gtype_name)
    }
  },
  mounted() {
    this.authCheck()
    this.userGet()
    this.getCompanyList()
    this.getDeviceList()
  },
  created() {
    this.$store.commit(
      'setLogin',
      JSON.parse(sessionStorage.getItem('loginInfo'))
    )
  }
}
</script>

<style lang="less" scoped>
.page {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  min-width: 1300px;
  min-height: 100vh;
  // min-height: 1080px;
}
.main {
  width: 1698px;
  height: auto;
  background: #ebecee;
}
.mian-wrapper {
  padding: 28px; // 边距
  width: 1698px;
  height: 879px;
  .main-manager {
    width: 1642px;
    height: 82.5vh;
    background: #fff;
    .userForm {
      padding: 25px 0 0 26px;
      width: auto;
      height: auto;
    }
  }

  ::v-deep {
    .el-form-item__label {
      width: 150px !important;
    }
    .el-input__inner {
      border-radius: 0px;
    }
    .el-form-item {
      margin-bottom: 28px;
      border-radius: 0px;
      .el-form-item__label {
        font-size: 18px;
        font-family: Source Han Sans CN;
      }
      .el-input {
        width: 332px;
        height: 42px;
      }
    }
    .el-form-item:nth-child(-n + 3) {
      margin-bottom: 20px;
    }
  }
}
</style>
